import React, { Component } from "react";
import moment from "moment";

class TaskCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTime: 0,
    };
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item !== this.props.item) {
    }
  }
  calTotalTime = () => {
  
    let totalTime;

    this.props.item?.timeData?.map((timeDataObj) => {
      if (timeDataObj.date === this.props.day.date) {
        totalTime = 0;
        timeDataObj.timeEntryData?.map((timeEntry) => {
          if (timeEntry.approvedType === "N") {
            totalTime = totalTime + timeEntry.time;
          }
        });
      }
    });

    //   this.setState({
    //     totalTime: totalTime,
    //   });

    return Math.round((totalTime + Number.EPSILON) * 100) / 100;
  };
  handleCheckedValue = () => {
    let isSelected;
    let anySelectedEntry = false;
    this.props.item?.timeData?.map((timeDataObj) => {
      let isSelectedTrueFrTimeDataObj = 1;
      let isSelectedFalseFrTimeDataObj = 0;
      if (timeDataObj.date === this.props.day.date) {
        // isSelected = timeDataObj.isSelected;

        timeDataObj?.timeEntryData?.map((timeEntry) => {
          timeEntry.isSelected === 1
            ? (isSelectedFalseFrTimeDataObj = 1)
            : (isSelectedTrueFrTimeDataObj = 0);
          timeEntry.isSelected === 1 && (anySelectedEntry = true);
        });
        isSelected =
          isSelectedFalseFrTimeDataObj === 0
            ? 0
            : isSelectedTrueFrTimeDataObj === 1
            ? 1
            : 0;
      }
    });
    // console.log("____________")
    //       console.log(this.props.item?.taskName +"-"+this.props.day.date+"-"+isSelected)
    // return isSelected === 1 ? true : false;
    // return {
    //   isSelected: isSelected === 1 ? true : false,
    //   anySelectedEntry: anySelectedEntry,
    // };
    return anySelectedEntry;
  };
  render() {
    return (
      <React.Fragment>
        {this.calTotalTime() > 0 && (
            <div className="ch-week-column-item">
            <div className="ch-card">
              <div className="card-header" title= {this.props.item.taskName} tabIndex={0}> {this.props.item.taskName}</div>
              <div className="card-hours" tabIndex={0} title={`${this.calTotalTime()} Hours`}>
                <div className="hr-value">  {this.calTotalTime()} </div>
                <div className="hr-lbl">Hours</div>
              </div>
              
              
              <div className="card-select">
              <input
                    className="form-check-input float-end"
                    type="checkbox"
                    checked={this.handleCheckedValue()}
                    title={`${this.handleCheckedValue()=== 1?"Selected":"Un Selected"}`}
                    tabIndex={0}
                    id="allSelectedCheckbox"
                    onChange={(event) =>
                      this.props.handleCheckBoxSelectionEventOfItemCard(
                        event.target.checked ? 1 : 0,
                        this.props.item,
                        this.props.day
                      )
                    }
                  />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TaskCard;
