import axios from "axios";
import React, { Component, Fragment } from "react";
import { getErrorMessage, patchMethod } from "../../utils/utils";
import envConfig from "../../environment-property/property.json";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "./pieChart";
import BarChart from "./barChart";
import moment from "moment";
import { getBarDetails } from "../../utils/services";

Chart.register(CategoryScale);

class ConfirmApprovedTimeforBillingModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        status: false,
        message: "",
      },

      temp: {},
      loading: false,
      data: {},
      dataListUpto7Week: [],
      initialState:true
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}

  calculatedTotalTime = (teamMember) => {
    let totalTime = 0;
    teamMember?.taskData?.map((taskDataObj) => {
      taskDataObj.timeData?.map((timeDataObj) => {
        timeDataObj.timeEntryData?.map((timeEntry) => {
          if (timeEntry.approvedType === "C") {
            totalTime = totalTime + timeEntry.time;
          }
        });
      });
    });
    return Math.round((totalTime + Number.EPSILON) * 100) / 100;
  };

  cancelOrConfirmForBilling = (type) => {
    let totalTime = 0;
    let approvedTimeMap = new Map();
    let approvedTimeList = [];

    this.setState({
      loading: true,
    });

    this.props.selectedProject.selectProject.pendingConfirmations?.map(
      (teamMember) => {
        teamMember?.taskData?.map((taskDataObj) => {
          taskDataObj?.timeData?.map((timeDataObj) => {
            timeDataObj?.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "C") {
                if (approvedTimeMap.has(timeEntry.timeRecordId)) {
                  let timeEntryArray = approvedTimeMap.get(
                    timeEntry.timeRecordId
                  );
                  timeEntryArray = [...timeEntryArray, timeEntry.timeEntryId];
                  approvedTimeMap.set(timeEntry.timeRecordId, timeEntryArray);

                  approvedTimeList = approvedTimeList?.map((obj) => {
                    if (obj.timeRecord === timeEntry.timeRecordId) {
                      return {
                        timeRecord: timeEntry.timeRecordId,
                        timeEntry: timeEntryArray,
                      };
                    } else {
                      return obj;
                    }
                  });
                } else {
                  approvedTimeMap.set(timeEntry.timeRecordId, [
                    timeEntry.timeEntryId,
                  ]);
                  approvedTimeList = [
                    ...approvedTimeList,
                    {
                      timeRecord: timeEntry.timeRecordId,
                      timeEntry: [timeEntry.timeEntryId],
                    },
                  ];
                }
              }
            });
          });
        });
      }
    );

    if (approvedTimeList.length > 0) {
      let value = {
        userName: this.props.loggedUser.email,
        identification: "W/A",
        approvedTime: approvedTimeList,
      };

      this.setState({
        temp: value,
      });

      this.cancelOrConfirmForBillingDataSave(
        type,
        value,
        this.props.selectedProject.gteProjectId
      );
    }
  };

  cancelOrConfirmForBillingDataSave = (type, value, projectId) => {
    this.props.loadingState();
    this.props.hide();

    patchMethod(
      envConfig.services.checkinBffServiceWebApproval,
      this.props.selectedProject.selectProject.gteProjectId,
      this.props.selectedDate.year,
      this.props.selectedDate.week,
      type,
      value
    )
      .then((response) => {
        this.props.closeModelAndUpdateData();
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          error: {
            status:  message?.status === 401 ?(false):(true),
            message: message,
          },
        });
      });
  };

  render() {
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <Fragment>
        <div className="modal show fade" style={modelStyle}>
          <div className="modal-dialog modal-width">
            <div className="summary_header">
              <div className="modal-content summary">
                <div className="modal-header"
                 ref={(inputElement) => {
                  if (inputElement) {
                    if ( this.state.initialState) {
                      inputElement.focus();
                    }
                    
                  }
                }}
                title=" Confirm Approved Time for Billing" tabIndex={0}>
                  Confirm Approved Time for Billing
                  <button
                  tabIndex={0}
                  title="Close"
                    type="button"
                    className="btn-close"
                    onClick={this.props.hide}
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-4">
                        <div className="col-12">
                        <div className="row t-head">
                          <div className="col-9 " title="Full Name" tabIndex={0}>Full Name</div>
                          <div className="col-3 t-head-title" title="Time" tabIndex={0}>Time</div>

                        </div>
                        </div>
                        
                        <div className="col-12 confirm-approved-table ">
                          {this.props.selectedProject?.selectProject?.pendingConfirmations?.map(
                            (teamMember) => (
                              <div className="row t-row">
                                <div className="col-9 team-member-name" title={teamMember.name} tabIndex={0}>{teamMember.name}</div>
                                <div className="col-3 t-time-tot" tabIndex={0} title={this.calculatedTotalTime(teamMember)}>
                                  {this.calculatedTotalTime(teamMember)}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {/* <table class="table table-sm table-hover">
                          <thead className="table-secondary">
                            <tr>
                              <th>Full Name</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody >
                            <span className="table-responsive">
                            {this.props.selectedProject?.selectProject?.pendingConfirmations?.map(
                              (teamMember) => (
                                <tr>
                                  <td>{teamMember.name}</td>
                                  <td>
                                    {this.calculatedTotalTime(teamMember)}
                                  </td>
                                </tr>
                              )
                            )}
                            </span>
                           
                          </tbody>
                        </table> */}
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col-12 ">
                            <BarChart
                            tabIndex={0}
                              dataListUpto7Week={
                                this.props.selectedProject?.selectProject
                                  ?.dataListUpto7Week
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-7">
                            <PieChart
                            tabIndex={0}
                              approveRejectDetails={
                                this.props.selectedProject?.selectProject
                                  ?.approveRejectDetails
                              }
                            />
                          </div>
                          <div className="col-5">
                            <div className="model-footer-button ">
                              <button
                                type="button"
                                className="btn btn-sm dark_btn"
                                // className="btn btn-outline-dark"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  this.cancelOrConfirmForBilling("U");
                                }}
                                title="Cancel"
                                tabIndex={0}
                              >
                                Cancel
                              </button>

                              <button
                                type="button"
                                // className="btn btn-success"
                                className="btn btn-sm green_btn flex-inline"
                                data-bs-dismiss="modal"
                                onClick={() =>
                                  this.cancelOrConfirmForBilling("C")
                                }
                                title="Confirm For Billing"
                                tabIndex={0}
                              >
                                Confirm For Billing
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm dark_btn"
                    // className="btn btn-outline-dark"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      this.cancelOrConfirmForBilling("U");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    // className="btn btn-success"
                    className="btn btn-sm green_btn"
                    data-bs-dismiss="modal"
                    onClick={() => this.cancelOrConfirmForBilling("C")}
                  >
                    Confirm For Billing
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ConfirmApprovedTimeforBillingModel;
