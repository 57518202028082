import React, { Component } from "react";
import "./App.css";
import "./Feedback.css";
import NavBar from "./components/navbar";
import MainMenu from "./components/mainMenu";
import envConfig from "./environment-property/property.json";
import AuthService from "./services/AuthService";
import {
  getloggedUserDetails,
  setAccessToken,
  setDefaultAccessToken,
  getTestAccessToken,
} from "./utils/utils";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loggedUser: null,
      isAuthenticated: false,
      isLoading: true,
    };
    this.AuthService = new AuthService();
    this.tokenCacheKey = envConfig.authConfiguration.tokenCacheKey;
  }

  async componentDidMount() {
    try {
      const login = await this.AuthService.initLogin();
      if (login) {
        const accessToken = await this.AuthService.getToken();

        setAccessToken(accessToken);

        if (envConfig.debug.isDebugEnable) {
          let params = new URL(document.location).searchParams;

          let displayName;
          let email;

          if (params != null) {
            displayName = params.get("employee");
            email = params.get("user-name");

            this.setState({
              isAuthenticated: true,
              isLoading: false,
              loggedUser: {
                displayName: displayName,
                email: email,
              },
            });
          } else {
            this.setState({
              loggedUser: envConfig.debug.defaultUser,
            });
          }
        } else {
          this.setState({
            isAuthenticated: true,
            isLoading: false,
            loggedUser: {
              displayName: getloggedUserDetails().name,
              email: getloggedUserDetails().userName,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleLogOut = () => {
    this.setState({ loggedUser: null });
  };

  handleLogIn = () => {
    this.AuthService.login();
  };

  renderMainComponent = () => {
    if (this.state.loggedUser !== null) {
      return (
        <MainMenu key="main-menu-key" loggedUser={this.state.loggedUser} />
      );
    } else {
      return (
        <div className="alert alert-light" role="alert">
          {/* You are logout! Please login. */}
        </div>
      );
    }
  };

  render() {
    let af_regionStyle = {
      position: "absolute",
      width: "auto",
      height: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
    return (
      <React.Fragment>
        <div className="ch-container">
          <NavBar
            key="nav-bar-key"
            loggedUser={this.state.loggedUser}
            logOut={this.handleLogOut}
            logIn={this.handleLogIn}
          />

          <main className="container-fluid">{this.renderMainComponent()}</main>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
