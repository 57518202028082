import React, { Component } from "react";

class TimeEntryCard extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.props.timeEntry.approvedType === "N" && (
          <div className="ch-week-column-item">
            <div className="ch-card">
              <div className="card-header" title= {this.props.timeEntry.timeType} tabIndex={0}>
                {" "}
                {this.props.timeEntry.timeType}
              </div>
              <div className="card-hours" tabIndex={0} title={`${this.props.timeEntry?.time} Hours`}>
                <div className="hr-value"> {this.props.timeEntry?.time}</div>
                <div className="hr-lbl">Hours</div>
              </div>
             
              <div className="invoice-comment-header">
                <div className="invoice-comment-label" title="Invoice Comment" tabIndex={0}>Invoice Comment</div>
                
                {(this.props.timeEntry.invoiceComment === "null" ||this.props.timeEntry.invoiceComment === null ||this.props.timeEntry.invoiceComment === "")?(
                  <div className="invoice-comment-value" tabIndex={0} title="Invoice Comments Not Available">
                 
                  Invoice Comments Not Available
                </div>
                ):( <div className="invoice-comment-value" title= {this.props.timeEntry.invoiceComment} tabIndex={0}>
             
                {this.props.timeEntry.invoiceComment}
              </div>)}
                
              </div>
              <div className="card-select">
                <input
                  className=" form-check-input float-end"
                  type="checkbox"
                  title={`${this.props.timeEntry.isSelected?"Selected":"Un Selected"}`}
                  tabIndex={0}
                  checked={this.props.timeEntry.isSelected}
                  id="allSelectedCheckbox"
                  onChange={(event) =>
                    this.props.handleCheckBoxSelectionEventOfItemCard(
                      event.target.checked ? 1 : 0,
                      this.props.timeEntry,
                      this.props.day
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TimeEntryCard;
