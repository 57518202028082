import envConfig from "../environment-property/property.json";
import axios from "axios";
import jwt from "jwt-decode";
import AuthService from "../services/AuthService";


const host = envConfig.services.host;

export function setAccessToken(accessToken) {
  const decoded = jwt(accessToken);

  let loggedUser = {
    name: decoded.name,
    userName: decoded.unique_name,
  };

  localStorage.setItem("logged-user", JSON.stringify(loggedUser));
  localStorage.setItem(envConfig.authConfiguration.tokenCacheKey, accessToken);
}

export async function validateAccessToken(accessToken) {
  if (Date.now() >= jwt(accessToken).exp * 1000) {
    const token_1 = await new AuthService().getToken();
    setAccessToken(token_1);
    return token_1;
  } else {
    return new Promise((resolve) => {
      resolve(accessToken);
    });
  }
}

export async function generateHeaderDetails(accessToken, subscriptionKey) {
  const validatedAccessToken = await validateAccessToken(accessToken);
  return {
    headers: {
      Authorization: "Bearer " + validatedAccessToken,
      "Ocp-Apim-Subscription-Key": subscriptionKey,
    },
  };
}
export function getloggedUserDetails() {
  return JSON.parse(localStorage.getItem("logged-user"));
}

// export function getErrorMessage(error) {
//   let message = "";
//   if (error.response) {
//     if (error.response.status === 404) {
//       message = "No Data To Display";
//     }
//     if (error.response.status === 400) {
//       message = "Bad Request";
//     }
//     if (error.response.status === 401) {
//       message = "Unauthorized attempt";
//     }
//   } else if (error.request) {
//     message =
//       " the request was never left & The client never received a response " +
//       error.request.status;
//   } else {
//     message = " nodatatodisplay..."+error?.status;
//   }

//   return message;
// }


export function getErrorMessage(error) {

  let value =
    "Opps! An Unexpected Error Occured. Please Reach out to EAG Support Team with a Screenshot of this error.  Request ID : ";

  let message = {};
  let requestId = "Not Applicable";
  if (error?.response) {
    requestId = getRequestId(error?.response);

    if (error?.response?.status === 404) {

      message = {
        message: "No Data To Display",
        status: error?.response?.status,
      };
    } else if (error.response?.status === 401) {
      message = {
        status: error.response?.status,
        message: {
          message: value + " " + requestId,
          status: error.response?.status,
        },
      };
    } else {
      message = {
        message: value + " " + requestId,
        status: error?.response?.status,
      };
    }
  } else if (error?.request) {
    requestId = getRequestId(error?.request);

    message = {
      message: value + " " + requestId,
      status: error?.response?.status,
    };
  } else {
    message = {
      message: value + " " + requestId,
      status: 0,
    };
  }

  // console.log("message ------.. ", message);
  return message;
}

function getRequestId(value) {
  let requestId = "Not Applicable";
  if (value?.hasOwnProperty("data")) {
    if (value?.data?.hasOwnProperty("message")) {
      var str_pos = value?.data?.message?.indexOf("RequestID");
      if (str_pos > -1) {
        requestId = value?.data?.message?.slice(
          value?.data?.message?.indexOf(":") + 1
        );
        // console.log("requestId");
      }
    }
  }
  return requestId;
}

export async function getProjectMethod(
  generalPath,
  functionPath,
  parameter1,
  value1,
  parameter2,
  value2,
  parameter3,
  value3
) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  return await axios.get(
    host +
    generalPath +
    functionPath +
    parameter1 +
    value1 +
    parameter2 +
    value2 +
    parameter3 +
    value3, config);
}

export async function getTeamMemberDataMethod(
  generalPath,
  functionPath,
  parameter1,
  value1,
  parameter2,
  value2,
  parameter3,
  value3,
  parameter4,
  value4
) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  return await axios.get(
    host +
    generalPath +
    functionPath +
    parameter1 +
    value1 +
    parameter2 +
    value2 +
    parameter3 +
    value3 +
    parameter4 +
    value4,
    config
  );
}
export async function getData_ForPieChart(
  generalPath,
  parameter1,
  projectId,
  parameter2,
  year,
  parameter3,
  weekNo,
  parameter4,
  userName,
  parameter5,
  state
) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  return await axios.get(
    host +
    generalPath +
    parameter1 +
    projectId +
    parameter2 +
    year +
    parameter3 +
    weekNo +
    parameter4 +
    userName +
    parameter5 +
    state,
    // "https://checkin-api-dev1.virtusa.com/checkin-bff-service/web/approval?project-id=19631&year=2023&week-no=23&user-name=sramanathan@virtusa.com&status=A,R",
    config
  );
}

export async function getData_ForBarChart(
  generalPath,
  parameter1,
  projectId,
  parameter2,
  year,
  parameter3,
  weekNo,
  parameter4,
  userName,
  parameter5,
  state
) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  return await axios.get(
    host +
    generalPath +
    parameter1 +
    projectId +
    parameter2 +
    year +
    parameter3 +
    weekNo +
    parameter4 +
    userName +
    parameter5 +
    state,
    // "https://checkin-api-dev1.virtusa.com/checkin-bff-service/web/approval?project-id=19631&year=2023&week-no=23&user-name=sramanathan@virtusa.com&status=A,R",
    config
  );
}

export async function patchMethod(generalPath, project, year, week, type, value) {
  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey);

  return await axios.patch(
    host + generalPath + project + "/" + year + "/" + week + "/" + type,
    value,
    config
  );
}

export function isDuplicate(data, obj) {
  return data.some((el) =>
    Object.entries(obj).every(([key, value]) => value === el[key])
  );
}

export function arrayFiltering(data, obj) {
  return data.filter((member) => {
    return member.gteEmployeeNo !== obj.gteEmployeeNo;
  });
}
export async function postFeedback(user_id, value) {

  const config = await generateHeaderDetails(localStorage.getItem(envConfig.authConfiguration.tokenCacheKey), envConfig.securityConfiguration.OcpApimSubscriptionKey_for_feedback);

  return await axios.post(
    // envConfig.services.feedback_host +
    // envConfig.services.virtusa_generic_feedback_crud_api +
    // envConfig.services.feedback +
    // envConfig.services.user_feedback,
    envConfig.services.user_feedback_uri,
    value, config
  );

}
export const sendFeedback = (user_id, value) => {
  return new Promise((resolve) => {
    resolve(
      postFeedback(user_id, value).then((res) => {
        return res.data;
      })
    );
  });
};