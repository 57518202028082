import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Data } from "../../utils/weekData";
// import { Data } from "../../utils/data/";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueForAccessibilityReading: "",
      data: {
        labels: this.props.dataListUpto7Week?.map(
          (data) => "Week " + data?.week
        ),
        datasets: [
          {
            label: "Time Pending Approval",
            data: this.props.dataListUpto7Week?.map(
              (data) => data.data.pendingApprovalTime
            ),
            backgroundColor: ["#f3ba2f"],
            borderColor: "black",
            borderWidth: 1,
          },
          {
            label: "Time Approved",
            data: this.props.dataListUpto7Week?.map(
              (data) => data.data.approvedTime
            ),
            backgroundColor: ["#16a086"],
            borderColor: "black",
            borderWidth: 1,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.readingValueForAccessibility();
    // this.updateDataForBarChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataListUpto7Week !== this.props.dataListUpto7Week) {
    }
  }

  readingValueForAccessibility() {
    let value = "Time Approval Trend \n ";
    for (let i = 0; i < this.props.dataListUpto7Week?.length; i++) {
      value +=
        "Week " +
          this.props.dataListUpto7Week[i]?.week +" : "+
          (this.props.dataListUpto7Week[i]?.data?.pendingApprovalTime >
        0
          ? "Time Pending Approval : " +
            this.props.dataListUpto7Week[i]?.data?.pendingApprovalTime +
            " Hours, "
          : "" )
          +
          (this.props.dataListUpto7Week[i]?.data?.approvedTime >
        0
          ? " Approved Time : " +
            this.props.dataListUpto7Week[i]?.data?.approvedTime +
            " Hours "
          : "" )
          +"\n"
         
    }
    console.log(value);
    this.setState({
      valueForAccessibilityReading: value,
    });
  }
  render() {
    return (
      <div
        className="chart-container"
        style={{ padding: "2px", width: "100%", height: "230px" }}
      >
        <Bar
          aria-label={this.state.valueForAccessibilityReading}
          tabIndex={0}
          data={this.state.data}
          options={{
            scaleShowValues: true,
            scales: {
              y: {
                ticks: {
                  autoSkip: false,
                },
              },
            },
            indexAxis: "y",
            elements: {
              bar: {
                borderWidth: 1,
              },
            },
            // responsive: true,
            plugins: {
              legend: {
                position: "right",
              },
              title: {
                position: "top",
                display: true,
                text: "Time Approval Trend",
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }
}
export default BarChart;
