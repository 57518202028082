import React, { Component } from "react";
import CheckinLoad from "../../logo/checkInLoad.gif";

class CheckInLoadPopup extends Component {
  render() {
    let af_regionStyle = {
      position: "absolute",
      width: "auto",
      height: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "block",
      backgroundColor: "transparent",
      cursor: "wait",
    };
    return (
      <React.Fragment>
        <div className="af_regionStyle" style={af_regionStyle}>
          {/* <img src={CheckinLoad} alt="Bootstrap" height="70" width="70" /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default CheckInLoadPopup;
