import React, { Component } from "react";
import moment from "moment";

class DayCard extends Component {
  // construtor() {


  //   // Create the ref in the constructor
  //   this.focusRef = React.createRef();
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if(prevProps.move !== this.props.move){
  //     if(this.props.move){
       
  //     }
  //   }
  // }

  render() {
    return (
      <div className="ch-week-header" >
        <div className="ch-day">
          <div
            className="ch-day-no"
            tabIndex={0}
            title={`${moment(this.props.day.date).format("DD")} ${moment(
              this.props.day.date
            ).format("dddd")}`}

            ref={(inputElement) => {
              if (inputElement) {
                if ( this.props.move && this.props.day.date === this.props.firstDay) {
                  inputElement.focus();
                }
                
              }
            }}

            onKeyUp={()=>{
              this.props.updateMoveState();
            }}

          >
            {moment(this.props.day.date).format("DD")}
            <span > {moment(this.props.day.date).format("dddd")}</span>
          </div>
          <div className="ch-day-select">
            {this.props.selectedProject.type !== "task" && (
              <input
                tabIndex={0}
                title={`${
                  this.props.day.isSelected === 1 ? "checked" : "unchecked"
                }`}
                className="form-check-input"
                type="checkbox"
                id="check1"
                name="option1"
                value="something"
                checked={this.props.day.isSelected === 1 ? true : false}
                onChange={(event) =>
                  this.props.handleCheckboxSelectionOfDayCard(
                    event.target.checked ? 1 : 0,
                    this.props.day
                  )
                }
              />
            )}
          </div>
        </div>
        <div className="ch-hours">
          <span tabIndex={0} title={`${this.props.day?.timeTotal} Hours`}>
            {this.props.day?.timeTotal} Hours
          </span>
        </div>
      </div>
    );
  }
}

export default DayCard;
