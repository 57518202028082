import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import authrizationConfig from "../environment-property/property.json";
import jwt from "jwt-decode";

class AuthService {
  constructor() {
    this.applicationConfig = {
      auth: {
        clientId: authrizationConfig.authConfiguration.clientId,
        // "app registerd clientId in azure potal",
        authority: authrizationConfig.authConfiguration.authority,
        // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: authrizationConfig.authConfiguration.redirectUri,
      },
      cache: {
        cacheLocation: authrizationConfig.authConfiguration.cacheLocation,
        storeAuthStateInCookie: true,
      },
    };
    this.app = new PublicClientApplication(this.applicationConfig);
    this.tokenCacheKey = authrizationConfig.authConfiguration.tokenCacheKey;
  }

  async initLogin() {
    await this.handleRedirectCallback();
    const accounts = this.app.getAllAccounts();
    if (accounts.length === 0) {
      this.login();
      return false;
    } else {
      return true;
    }
  }

  async getToken() {
    const cachedToken = localStorage.getItem(this.tokenCacheKey);

    if (cachedToken) {
      const decoded = jwt(cachedToken);

      if (Date.now() < decoded.exp * 1000) {
        console.log("[Dev Log]: TOKEN NOT EXPIRED");
        return cachedToken;
      } else {
        console.log("[Dev Log]: TOKEN EXPIRED");
      }
    }

    const accounts = this.app.getAllAccounts();

    const silentRequest = {
      scopes: authrizationConfig.authConfiguration.scopes,
      account: accounts[0],
    };

    try {
      const response = await this.app.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await this.app.acquireTokenRedirect(silentRequest);
          return response.accessToken;
        } catch (error) {
          console.error(error);
          return null;
        }
      } else {
        console.error(error);
        return null;
      }
    }
  }

  async login() {
    const loginRequest = {
      scopes: authrizationConfig.authConfiguration.scopes,
    };
    this.app.loginRedirect(loginRequest);
  }

  logout() {
    this.app.logout();
  }

  async handleRedirectCallback() {
    return await this.app.handleRedirectPromise().then((response) => {
      if (response && response.accessToken) {
        localStorage.setItem(this.tokenCacheKey, response.accessToken);
      }
    });
  }
}

export default AuthService;
