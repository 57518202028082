import React, { Component } from "react";
import envConfig from "../../environment-property/property.json";
import DayCard from "../commonComponents/dayCard";
import moment from "moment";
import TeamMemberCard from "../commonComponents/teamMemberCard";
import TaskCard from "../commonComponents/taskCard";
import TimeEntryCard from "../commonComponents/timeEntryCard";

class TimeApproveMainPage extends Component {
  // state = {
  //   firstDayofCurrentWeek: moment().startOf("isoweek").format("YYYY-MM-DD"),
  //   selectedProject: {},
  //   days: [],
  // };

  // selectedProject: {zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz
  //   selectProject: {},
  //   type: "", // project teamMember task
  //   data: {},
  // }

  componentDidMount() {
    // this.setData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
    }
  }

  calTotalTimeForTeamMember = (teamMember) => {
    let BillableTotalTime = 0;
    let nonBillableTotalTime = 0;
    let totalTime = 0;
    teamMember.taskData?.map((taskDataObj) => {
      taskDataObj.timeData?.map((timeDataObj) => {
        if (timeDataObj.date === this.props.day.date) {
          timeDataObj.timeEntryData?.map((timeEntry) => {
            if (timeEntry.approvedType === "N") {
              totalTime = totalTime + timeEntry.time;
            }
          });
          if (taskDataObj.isBillable === "Y") {
            timeDataObj.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "N") {
                BillableTotalTime = BillableTotalTime + timeEntry.time;
              }
            });
          }
          if (taskDataObj.isBillable === "N") {
            timeDataObj.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "N") {
                nonBillableTotalTime = nonBillableTotalTime + timeEntry.time;
              }
            });
          }
        }
      });
    });

    return {
      totalTime: Math.round((totalTime + Number.EPSILON) * 100) / 100,
      BillableTotalTime:
        Math.round((BillableTotalTime + Number.EPSILON) * 100) / 100,
      nonBillableTotalTime:
        Math.round((nonBillableTotalTime + Number.EPSILON) * 100) / 100,
    };
  };
  render() {
    return (
      <React.Fragment>
        {this.props.selectedDate.days?.map((day) => (
          <div className="ch-week-column"
          >
            <span>
              <DayCard
               updateMoveState ={()=>{this.props.updateMoveState()}}
                move={this.props.move}
                firstDay={this.props.selectedDate.days[0].date}
                key={day.date}
                day={day}
                selectedProject={this.props.selectedProject}
                handleCheckboxSelectionOfDayCard={(value, day) => {
                  this.props.handleCheckboxSelectionOfDayCard(value, day);
                }}
              />
            </span>
            {this.props.selectedProject.type === "project" &&
              this.props.selectedProject?.selectProject?.teamMembers?.map(
                (teamMember) => (
                  <span>
                    <TeamMemberCard
                      key={
                        day.date +
                        "-" +
                        this.props.selectedProject.selectProject.gteProjectId
                      }
                      selectedProject={this.props.selectedProject}
                      item={teamMember}
                      day={day}
                      handleCheckBoxSelectionEventOfItemCard={(
                        value,
                        item,
                        day
                      ) => {
                        this.props.handleCheckBoxSelectionEventOfItemCard(
                          value,
                          item,
                          day
                        );
                      }}
                    />
                  </span>
                )
              )}
            {this.props.selectedProject.type === "teamMember" &&
              this.props.selectedProject?.selectProject?.teamMembers?.map(
                (member) =>
                  member.gteEmployeeNo ===
                    this.props.selectedProject.gteEmployeeNo &&
                  member.taskData?.map((task) =>
                    task.timeData?.map(
                      (timeDataObj) =>
                        timeDataObj.date === day.date && (
                          <span>
                            <TaskCard
                              item={task}
                              day={day}
                              handleCheckBoxSelectionEventOfItemCard={(
                                value,
                                item,
                                day
                              ) => {
                                this.props.handleCheckBoxSelectionEventOfItemCard(
                                  value,
                                  item,
                                  day
                                );
                              }}
                            />
                          </span>
                        )
                    )
                  )
              )}

            {this.props.selectedProject.type === "task" &&
              this.props.selectedProject?.selectProject?.teamMembers?.map(
                (member) =>
                  member.gteEmployeeNo ===
                    this.props.selectedProject.gteEmployeeNo &&
                  member.taskData?.map(
                    (task) =>
                      task.taskId === this.props.selectedProject.taskId &&
                      task.timeData?.map(
                        (timeDataObj) =>
                          timeDataObj.date === day.date &&
                          timeDataObj?.timeEntryData?.map(
                            (timeEntry) =>
                              timeEntry.time > 0 && (
                                <span>
                                  <TimeEntryCard
                                    timeEntry={timeEntry}
                                    day={day}
                                    handleCheckBoxSelectionEventOfItemCard={(
                                      value,
                                      item,
                                      day
                                    ) => {
                                      this.props.handleCheckBoxSelectionEventOfItemCard(
                                        value,
                                        item,
                                        day
                                      );
                                    }}
                                  />
                                </span>
                              )
                          )
                      )
                  )
              )}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default TimeApproveMainPage;
// export default React.memo(TimeApproveMainPage);
