import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";

class TeamMemberCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTime: 0,
      BillableTotalTime: 0,
      nonBillableTotalTime: 0,
      percentage: 0,
    };
  }
  componentDidMount() {
    this.calTotalTime();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item !== this.props.item) {
      this.calTotalTime();
    }
  }

  calTotalTime = () => {
    let BillableTotalTime = 0;
    let nonBillableTotalTime = 0;
    let totalTime = 0;
    this.props.item?.taskData?.map((taskDataObj) => {
      taskDataObj.timeData?.map((timeDataObj) => {
        if (timeDataObj.date === this.props.day.date) {
          timeDataObj.timeEntryData?.map((timeEntry) => {
            if (timeEntry.approvedType === "N") {
              totalTime = totalTime + timeEntry.time;
            }
          });
          if (taskDataObj.isBillable === "Y") {
            timeDataObj.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "N") {
                BillableTotalTime = BillableTotalTime + timeEntry.time;
              }
            });
          }
          if (taskDataObj.isBillable === "N") {
            timeDataObj.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "N") {
                nonBillableTotalTime = nonBillableTotalTime + timeEntry.time;
              }
            });
          }
        }
      });
    });
    let percentage = 0;
    if (totalTime > 0) {
      percentage = (BillableTotalTime / totalTime) * 100;
      // console.log("percentage ", percentage);
    }

    this.setState({
      totalTime: Math.round((totalTime + Number.EPSILON) * 100) / 100,
      BillableTotalTime:
        Math.round((BillableTotalTime + Number.EPSILON) * 100) / 100,
      nonBillableTotalTime:
        Math.round((nonBillableTotalTime + Number.EPSILON) * 100) / 100,
      percentage: percentage,
    });
    return { percentage: percentage };
  };
  handleCheckedValue = () => {
    let isSelected;
    let anySelectedEntry = false;
    let isSelectedTrueFrTimeDataObj = 1;
    let isSelectedFalseFrTimeDataObj = 0;
    this.props.item?.taskData?.map((taskDataObj) => {
      taskDataObj.timeData?.map((timeDataObj) => {
        if (timeDataObj.date === this.props.day.date) {
          // isSelected = timeDataObj.isSelected;

          timeDataObj.isSelected === 1
            ? (isSelectedFalseFrTimeDataObj = 1)
            : (isSelectedTrueFrTimeDataObj = 0);
          timeDataObj?.timeEntryData?.map((timeEntry) => {
            timeEntry.isSelected === 1 && (anySelectedEntry = true);
          });
        }
        isSelected =
          isSelectedFalseFrTimeDataObj === 0
            ? 0
            : isSelectedTrueFrTimeDataObj === 1
            ? 1
            : 0;
      });
    });
    // console.log("____________")
    //       console.log(this.props.item?.taskName +"-"+this.props.day.date+"-"+isSelected)
    // return {
    //   isSelected: isSelected === 1 ? true : false,
    //   anySelectedEntry: anySelectedEntry,
    // };
    return anySelectedEntry;
  };
  render() {
    return (
      <React.Fragment>
        {this.state.totalTime > 0 && (
          <div className="ch-week-column-item">
            <div className="ch-card">
              <div className="card-header" tabIndex={0} title={`${this.props.item.name}`}> {this.props.item.name}</div>
              <div className="card-hours" tabIndex={0} title={`${this.state.totalTime} Hours`}>
                <div className="hr-value"> {this.state.totalTime} </div>
                <span className="hr-lbl">Hours</span>
              </div>
              <div className="time-progress"></div>
              <div className="time-info" tabIndex={0} title={`Leave & Holiday ${this.state.nonBillableTotalTime} Hours`}>
                <span className="time-info-desc">Leave & Holiday</span>
                <div className="time-info-hrs">
                  {this.state.nonBillableTotalTime}
                </div>
                <span className="time-info-lbl">Hrs</span>
              </div>
              <div className="card-select">
                <input
                  className="form-check-input float-end"
                  type="checkbox"
                  checked={this.handleCheckedValue()}
                  title={`${this.handleCheckedValue()===1?"Selected":"Un Selected"}`}
                  id="allSelectedCheckbox"
                  onChange={(event) =>
                    this.props.handleCheckBoxSelectionEventOfItemCard(
                      event.target.checked ? 1 : 0,
                      this.props.item,
                      this.props.day
                    )
                  }
                  onClick={(event) =>
                    this.props.handleCheckBoxSelectionEventOfItemCard(
                      event.target.checked ? 1 : 0,
                      this.props.item,
                      this.props.day
                    )
                  }
                
                  tabIndex={0}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TeamMemberCard;
