import React, { Component } from "react";
import CheckInLogo from "../../logo/gte_logo.png";

class notFound extends Component {
  render() {
    return (
      <>
        <nav className="navbar">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img
                src={CheckInLogo}
                title="Time Approval"
                alt="Time Approval Logo"
                height="70"
              />
            </a>
            <div className="d-flex"></div>
          </div>
        </nav>
        <div className="center_nf">
          <div className="row inline-row">
            <div className="f04">
              404: The page you requested could not be found.
            </div>
            <div className="pnf">
              <a href="/" className="pnfA">
                Home
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default notFound;
