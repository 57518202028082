import axios from "axios";
import { getData_ForBarChart, getData_ForPieChart, getRequestMethod, getTeamMemberDataMethod } from "./utils";
import envConfig from "../environment-property/property.json";
import { getTeamMemberAndTaskList_Dummy } from "./data";

/*
 *  Here Handle Async Data Loading for Team Member & Task List
 */

export const fetchData = (project, year, week,loggedUser) => {
  const result = fetchTeamMembersList(project, year, week,loggedUser);

  return result;
  // return {
  //   projects: projectList,
  //   // projects: wrapPromise(projectList),
  // };
};

const fetchTeamMembersList = (project, year, week,loggedUser) => {

  return new Promise((resolve) => {
    resolve(
     
  
      getTeamMemberDataMethod(
        envConfig.services.checkinBffServiceWebApproval,
        envConfig.services.projectTeamMemberDetails,
        "?project-id=",
        project.gteProjectId,
        "&year=",
        year,
        "&week-no=",
        week,
        "&user-name=",
        loggedUser.email
      ).then((res) => {
       
        let data = res?.data;
       
        let newtotalTimeNmembersList =
        calTotalTimeNupdateTeammemberObject(data);

      
        let Updateproject= {
          projectName: project.projectName,
          gteProjectId: project.gteProjectId,
          isExpand: false,
          totalTime:
            (newtotalTimeNmembersList.totalTimeOfPendingApproval +
            newtotalTimeNmembersList.totalTimeOfPendingConfirmation),
          totalTimeOfPendingConfirmation:
            newtotalTimeNmembersList.totalTimeOfPendingConfirmation,
          teamMembers: newtotalTimeNmembersList.teamMembersList,
          totalTimeOfApproved: 0,
          totalTimeOfReject: 0,
          pendingConfirmations:
            newtotalTimeNmembersList.pendingConfirmationList,
        };

        return {
          project: Updateproject,
          year:year,
          week:week,
          loggedUser:loggedUser
        };

 
      })
    );
  });
};

const calTotalTimeNupdateTeammemberObject = (data) => {
  let totalTimeOfPendingApproval = 0.0;
  let totalTimeOfPendingConfirmation = 0;
  let anySelectedEntry=0
  let teamMemberList = data?.pendingApproval?.map((teamMember) => {
    anySelectedEntry=0
    let anySelectedEntryTask=0
    // "taskId": 1000,"taskName": "Development", "Billable": "Y","timeData":[]
    // let isSelectedTrueFrTeamMember =1 // if its not change this value remains same
    // let isSelectedFalseFrTeamMember =0 
    let taskDataList = teamMember?.taskData?.map((task) => {
      
      anySelectedEntryTask=0
      let timeDataList = task?.timeData?.map((timeDataObj) => {
        let timeEntryDataList = timeDataObj?.timeEntryData?.map(
          (timeEntry) => {
            totalTimeOfPendingApproval =
              totalTimeOfPendingApproval + timeEntry.time;
              // timeEntry.isSelected ===1?(isSelectedFalseFrTask=1):(isSelectedTrueFrTask=0)
              timeEntry.isSelected ===1&&(anySelectedEntry=1)
              timeEntry.isSelected ===1&&(anySelectedEntryTask=1)
            return { ...timeEntry, approvedType: "N" };
            // approvedType -> which type ->initialy -> "N" [not get for any type of reject or approved] -> "R"-> when click "Reject Selection" or "A"-> when click "Approve Selection"
          }
        );

        return {
          ...timeDataObj,
          timeEntryData: timeEntryDataList,
          // isSelected:  isSelectedFalseFrTask===0?(0):(isSelectedTrueFrTask===1?(1):(0)),
          isSelected:anySelectedEntryTask
        };

        // }
      });
    
      return {
        
        ...task,
        timeData: timeDataList,
        isSelected:anySelectedEntryTask,
        isChanged: false,
        anySelectedEntry:anySelectedEntry
      };
    });

   
    return {
      ...teamMember,
      isExpand: false,
      isSelected:anySelectedEntry,
      isChanged: false,
      taskData: taskDataList.sort((a, b) =>
      a.taskName > b.taskName ? 1 : -1
    ),
    
    };
  });

  let pendingConfirmationList = data?.pendingConfirmation?.map(
    (teamMember) => {
      // "taskId": 1000,"taskName": "Development", "Billable": "Y","timeData":[]
      let taskDataList = teamMember?.taskData?.map((task) => {
        let timeDataList = task?.timeData?.map((timeDataObj) => {
          let timeEntryDataList = timeDataObj?.timeEntryData?.map(
            (timeEntry) => {
              totalTimeOfPendingConfirmation =
                totalTimeOfPendingConfirmation + timeEntry.time;
              return { ...timeEntry, approvedType: "C" };
              // approvedType -> which type ->initialy -> "N" [not get for any type of reject or approved] -> "R"-> when click "Reject Selection" or "A"-> when click "Approve Selection"
           
            }
          );

          return {
            ...timeDataObj,
            timeEntryData: timeEntryDataList,
            isSelected: 1,
            isDisable:true
          };

        });
        return {
          ...task,
          timeData: timeDataList,
          isSelected: 1,
          isDisable: true,
          isChanged: false,
        };
      });

      return {
        ...teamMember,
        isExpand: false,
        isSelected: 0,
        isChanged: false,
        taskData: taskDataList.sort((a, b) =>
        a.taskName > b.taskName ? 1 : -1
      ),
        isDisable: true,
      };
    }
  );

 
  return {
    teamMembersList: teamMemberList.sort((a, b) =>
    a.name > b.name ? 1 : -1
  ),
    totalTimeOfPendingApproval:
      Math.round((totalTimeOfPendingApproval + Number.EPSILON) * 100) / 100,
    totalTimeOfApproved: 0,
    totalTimeOfReject: 0,
    pendingConfirmationList: pendingConfirmationList.sort((a, b) =>
    a.name > b.name ? 1 : -1
  ),
    totalTimeOfPendingConfirmation:
      Math.round((totalTimeOfPendingConfirmation + Number.EPSILON) * 100) /
      100,
  };


};


export const getPieChartDetails = (project, year, week,loggedUser) => {
  const result = returnApprovedRejectTimeData(project, year, week,loggedUser);

  return result;
};
const returnApprovedRejectTimeData = (project, year, week,loggedUser) => {
  return new Promise((resolve) => {
 
    resolve(
     
 
      getData_ForPieChart(
        envConfig.services._checkinBffServiceWebApproval,
        "project-id=",
        project?.gteProjectId,
        "&year=",
        year,
        "&week-no=",
        week,
        "&user-name=",
        loggedUser.email,
        "&status=",
        "A,R"
      ).then((res) => {
       let approveRejectDetails=res?.data
        return {
          project: {...project,approveRejectDetails:approveRejectDetails},
          year:year,
          week:week,
          loggedUser:loggedUser,
          approveRejectDetails:approveRejectDetails
        };

 
      })
    );
  });
};

export const getBarDetails = (gteProjectId, year, week,loggedUser,object) => {
  const result = returnBarCharData(gteProjectId, year, week,loggedUser,object);

  return result;
};
const returnBarCharData = (gteProjectId, year, week,loggedUser,object) => {
  return new Promise((resolve) => {
 
    resolve(
     
 
      getData_ForBarChart(
        envConfig.services._checkinBffServiceWebApproval,
        "project-id=",
        gteProjectId,
        "&year=",
        year,
        "&week-no=",
        week,
        "&user-name=",
        loggedUser.email,
        "&status=",
        "A,U"
      ).then((res) => {
       return {...object, data:res?.data};
      })
    );
  });
};