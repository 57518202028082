import React, { Component } from "react";
import { Doughnut, Pie } from "react-chartjs-2";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        // title: 'Approved Time Pending Confirmation for Billing',
        labels: [
          "Approved Time to be Confirmed for Billing",
          "Time Not Confirmed for Billing",
        ],

        datasets: [
          {
            data: [
              this.props.approveRejectDetails?.approvedTime,
              this.props.approveRejectDetails?.rejectedTime,
            ],
            backgroundColor: ["#16a086", "#E24131"],
          },
        ],
      },
    };
  }
  render() {
    return (
      <div className="chart-container">
        <div className="row">
          <div className="col-15" style={{ width: "100%", height: "268px" }}>
            <Doughnut
            tabIndex={0}
              data={this.state.data}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Approved Time Pending Confirmation for Billing",
                  },
                  legend: {
                    display: true,
                  },
                },
              }}
              // title={`${this.state.data?.labels[0]}: ${this.state.data?.datasets[0]?.data[0]} Hours and ${this.state.data?.labels[1]}: ${this.state.data?.datasets[0]?.data[1]} Hours`}
              aria-label={`${this.state.data?.labels[0]}: ${this.state.data?.datasets[0]?.data[0]} Hours and ${this.state.data?.labels[1]}: ${this.state.data?.datasets[0]?.data[1]} Hours`}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PieChart;
