import React, { Component, Fragment } from "react";
import CheckInLogoNew from "../../logo/ch_timeApproval_popup.png";
import envConfig from "../../environment-property/property.json";

class banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // bannerVisible: !document.cookie.includes("chk_ta_info_banner=true"),
      // feedbackVisible: !document.cookie.includes("chk_ta_feedback=true"),
      bannerVisible: envConfig.banner.showBanner,
      feedbackVisible: envConfig.showFeedback,
      toast: false,
      initialState: true,
      banner:
        "Checkin Banner for What is new in Checkin Time Approval Page. \n 1 Improved Performance. \n 2 Time entered for leave and holiday Tasks at glance at Employee Level",
    };
  }

  handleBannerClose = () => {
    // document.cookie =
    //   "chk_ta_info_banner=true; expires=Tue, 31 Dec 4712 23:59:59 GMT";

    this.setState({ bannerVisible: false });
    this.props.bannerVisible(false);
  };

  render() {
    const { bannerVisible } = this.state;

    let modelStyle = {
      display: bannerVisible ? "block" : "none",
      backgroundColor: "rgba(0,0,0,0.8)",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    };

    return (
      <div>
        <Fragment>
          <div className="ch-notif-banner">
            <div className="banner modal show " style={modelStyle}>
              <div
                className="banner modal-dialog modal-width"
                ref={(inputElement) => {
                  if (inputElement) {
                    if (this.state.initialState) {
                      inputElement.focus();
                    }
                  }
                }}
              >
                <div
                  className="banner modal-content"
                  title={this.state.banner}
                  tabIndex={0}
                >
                  <button
                    tabIndex={0}
                    type="button"
                    title="close"
                    className="banner btn-close"
                    onClick={this.handleBannerClose}
                  ></button>
                  <div>
                    <div className="bannermodal-body">
                      <img
                        src={CheckInLogoNew}
                        alt={this.state.banner}
                        tabIndex={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default banner;
