import React, { Component } from "react";
import CheckInLogo from "../logo/gte_logo.png";

class NavBar extends Component {
  renderLogInAndLogoutOption = () => {
    if (this.props.loggedUser == null) {
      return (
        <li className="nav-item">
          <button
            className="btn btn-outline-success"
            type="submit"
            onClick={this.props.logIn}
          >
            Log in
          </button>
        </li>
      );
    } else {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title= {this.props.loggedUser.displayName}
          >
            {this.props.loggedUser.displayName}
          </a>
          <ul className="dropdown-menu">
            <li>
              <a
              title="Home"
                className="dropdown-item"
                onClick={this.props.logOut}
                href="https://ecgy.fa.ap2.oraclecloud.com:443/fscmUI/faces/FuseWelcome"
              >
                Home
              </a>
            </li>
          </ul>
        </li>
      );
    }
  };

  render() {
    return (
      <nav className="navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={CheckInLogo}
              title="Time Approval"
              alt="Time Approval Logo"
              height="70"
            />
          </a>
          <div className="d-flex">
            <ul className="navbar-nav logged-user">
              {this.renderLogInAndLogoutOption()}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
